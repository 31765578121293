<template>
	<div class="loginForm_box">
		<div class="box1">
			<div class="typeinfo">
				<div class="typeobj" @click="handlelogintype(0)" :class="{'active':loginform.typeindex==0}">用户名登录</div>
				<div class="typeobj" @click="handlelogintype(1)" :class="{'active':loginform.typeindex==1}">短信验证码登录</div>
			</div>
			<div class="block10"></div>
			<div class="inputbox" v-if="loginform.typeindex==0">
				<el-input
				    placeholder="请输入用户名"
				    prefix-icon="el-icon-user"
				    v-model="loginform.username">
				  </el-input>
			</div>
			<div class="inputbox" v-if="loginform.typeindex==0">
				<el-input
				    placeholder="请输入密码"
				    prefix-icon="el-icon-lock"
					type="password"
				    v-model="loginform.password">
				  </el-input>
			</div>
			<div class="inputbox" v-if="loginform.typeindex==1">
				<el-input
				    type="number"
				    placeholder="请输入手机号"
				    prefix-icon="el-icon-phone"
				    v-model="loginform.phone">
				</el-input>
			</div>
			<div class="inputbox" v-if="loginform.typeindex==1">
				<div class="flexrow">
					<el-input type="number" prefix-icon="el-icon-news" v-model="loginform.captcha" placeholder="请输入验证码"></el-input>
					<el-button style="margin-left: 10px;" type="primary" @click="setcaptcha" :disabled="getCodeisWaiting">{{captchatext}}</el-button>
				</div>
			</div>
			<div class="block10"></div>
			<div class="checkbox" v-if="modetype=='pc'"><el-checkbox v-model="loginform.autologin"></el-checkbox><span class="ml">自动登录</span></div>
			<div class="checkbox" v-if="modetype=='phone'"><el-checkbox v-model="loginform.autologin"></el-checkbox><span class="ml">记住账号密码 </span></div>
			<div class="checkbox"><el-checkbox v-model="loginform.isgreen"></el-checkbox><span class="ml">选择同意</span><span class="zhuse pointer" @click="handleSearche('agreement')">《{{bsnName}}用户协议》</span></div>
			<div class="loginbtn" @click="handleLogin" v-if="!loginWaiting">登录</div>
			<div class="loginbtn" v-if="loginWaiting">登录中...</div>
			<div class="forgetbox" v-if="modetype=='pc'">
				<div class="text" @click="handlezhuce('zhuce')">没有账号？去注册</div>
				<div class="text" @click="handlezhuce('password')">忘记密码</div>
			</div>
			<div class="forgetbox" v-if="modetype=='phone'">
				<div class="text" @click="showmodal('zhuce')">没有账号？去注册</div>
			</div>
		</div>
		<!-- 注册弹窗 -->
		<registerForm :loginwidth="loginwidth" :isShow="zhuceshow" :istype="istype" @handlezhuceiForm="handlezhuceiForm" titlename="注册"></registerForm>
		<!-- 忘记密码弹窗 -->
		<registerForm :loginwidth="loginwidth" :isShow="getpasswordshow" :istype="istype" @handlezhuceiForm="handlezhuceiForm" titlename="忘记密码"></registerForm>
	</div>
</template>
<script>
	import {mapState,mapMutations} from 'vuex'
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	import  registerForm  from "../components/registerForm/registerForm.vue";
	export default {
		components: {
			registerForm
		},
		computed: {
			...mapState(['isLogin','bsnName','userInfo','appid','openid']),
		},
		data() {
			return {
				rules: {
					afterstar: [{
						required: true,
						message: '此项为必填项',
						trigger: 'blur'
					}]
				},
				loginform:{
					typeindex:0,
					username:"",
					password:"",
					captcha:'',
					phone:"",
					autologin:true,
					isgreen:true,
				},
				captchatext:"发送验证码",
				loginWaiting: false, // 登录禁止多次点击
				getCodeisWaiting: false, // 禁止多次点击
				istype:"add",//注册还是找回密码
				zhuceshow:false,//注册
				getpasswordshow:false,//找回密码
				modetype:"pc",//电脑
				loginwidth:"50%",//注册弹窗宽度
			};
		},
		created() {
			if (this.isWeixinBrowser()&&!this.openid) {
			    console.log('当前浏览器是微信浏览器');
			    // 在这里可以根据需要执行特定的逻辑，比如跳转到特定的页面或者执行其他操作
				if(this.$route.query&&this.$route.query.code){
					this.loginUser(this.$route.query.code)
				}else{
					this.redirectUrl("login","https://camp.heliheli.top/login")
				}
			}
			let width = document.documentElement.clientWidth
			if(this.isLogin){
				// 如果为桌面设备，再根据页面宽度判断是否需要切换为移动端展示
				if (width < 758) {
					//手机设备
				    console.log("phone")
					this.modetype = "phone"
					this.$router.replace("/recordPagePhone")
				} else {
				    this.$router.replace("/")
				}
			}else{
				// 如果为桌面设备，再根据页面宽度判断是否需要切换为移动端展示
				if (width < 768) {
					//手机设备
				    console.log("phone")
					this.modetype = "phone"
					//location.href = "/loginPhone"
					this.loginwidth = "95%"
				}
				if(localStorage.getItem("loginform")){
					var loginform = JSON.parse(localStorage.getItem("loginform"))
					this.loginform.password = loginform.password
					this.loginform.username = loginform.username
				}
			}
		},
		methods: {
			...mapMutations(["SET_USERINFO",'SET_ISLOGIN','SET_vipTpyes','SET_openid']),
			//手机端注册提示
			showmodal(){
				var _this = this
				var textContent = "请转至电脑端或平板端完成注册账号与登录使用（移动端产品开发中）。<br>注册登录链接：https://camp.heliheli.top/login"
				this.$alert(textContent, '温馨提示', {
				    confirmButtonText: '复制链接并关闭',
					dangerouslyUseHTMLString: true,
				    callback: action => {
						_this.$copyText('https://camp.heliheli.top/login').then(function (e) {
						    _this.$message.success("复制链接成功")
						}, function (e) {
						    console.log(e)
						})
					}
				});
			},
			//重定向
			redirectUrl(params,REDIRECT_URI){
			    var  REDIRECT_URI = encodeURIComponent(REDIRECT_URI) //"http://free.qudone.com/";  //http://free.qudone.com/  https://free.qudone.com/
			    var SCOPE = "snsapi_base"; //"snsapi_userinfo  snsapi_base";
			    var appId = this.appid;
			    var redirectUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+appId+"&redirect_uri="+REDIRECT_URI+"&response_type=code&scope="+SCOPE+"&state="+params+"&connect_redirect=1#wechat_redirect";
			    window.location.href = redirectUrl;
			},
			isWeixinBrowser() {
			    const ua = window.navigator.userAgent.toLowerCase();
				console.log(ua)
			    return ua.match(/MicroMessenger/i) == 'micromessenger';
			},
			//获取openid
			loginUser(code){
				var _this = this
				var params = {
					appid:this.appid,
					code:code
				}
				this.$http.post("app/WxMaModule/wx-mp-user/codeToUser", params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						if(res.data){
							_this.SET_openid(res.data.openId || res.data.openid)
							localStorage.setItem('key_state',JSON.stringify(_this.$store.state))
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//调整
			handleSearche(name){
				this.$router.push({
					name,
				});
			},
			//注册
			handlezhuce(type){
				if(type == 'zhuce'){
					this.istype = 'add'
					this.zhuceshow  = true
				}else{
					this.istype = 'password'
					this.getpasswordshow = true
				}
			},
			//打开注册认证
			handlezhuceiForm(type){
				if(this.istype == 'add'){
					if(type=="open"){
						this.zhuceshow = true
					}else{
						this.zhuceshow = false
					}
				}else{
					if(type=="open"){
						this.getpasswordshow = true
					}else{
						this.getpasswordshow = false
					}
				}
			},
			//登录
			handleLogin(){
				var _this = this
				if(!this.loginform.isgreen){
					this.$message.error("请先勾选同意用户协议")
					return false;
				}
				var params = {}
				var control = 'app/hlexam/login/login'
				if(this.loginform.typeindex==0){
					if(!this.loginform.username){
						this.$message.error("请先输入用户名")
						return false;
					}
					if(!this.loginform.password){
						this.$message.error("请先输入密码")
						return false;
					}
					params["password"] = this.loginform.password
					params["username"] = this.loginform.username
					//保存密码
					localStorage.setItem("loginform", JSON.stringify(this.loginform));//记住账号
				}else{
					if(!this.loginform.phone){
						this.$message.error("请先输入手机号")
						return false;
					}
					if(!this.loginform.captcha){
						this.$message.error("请先输入验证码")
						return false;
					}
					params["phone"] = this.loginform.phone
					params["captcha"] = this.loginform.captcha
					control = 'app/hlexam/login/loginPhone'
				}
				this.loginWaiting = true //禁止点击
				showLoading()
				this.$http.post(control, params).then(function(res) {
					hideLoading()
					_this.loginWaiting = false //禁止点击
					//数据处理
					if (res.code == 200) {
						_this.$message.success("登录成功")
						localStorage.setItem('token',res.data.token);
						_this.SET_ISLOGIN(true)
						_this.SET_USERINFO(res.data)
						//获取套餐等级字典
						//_this.getdictKeyIntValue()
						localStorage.setItem('key_state',JSON.stringify(_this.$store.state))
						if(_this.modetype == "phone"){
							//手机端去查看记录recordPagePhone
							_this.$router.replace("/recordPagePhone")
						}else{
							_this.$router.replace("/")
						}
						
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//获取套餐等级字典
			getdictKeyIntValue(){
				var _this = this
				var params = {
					label:"member_level"
				}
				showLoading()
				this.$http.post("app/dict/dictKeyIntValue", params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						var records = res.data;
						var vipTpyes = {}
						records.forEach((item, index) => {
							const propertyName = Number(item.key)
							vipTpyes[propertyName] = item.value
						});
						_this.SET_vipTpyes(vipTpyes)
						localStorage.setItem('key_state',JSON.stringify(_this.$store.state))
						_this.$router.replace("/")
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//登录类型
			handlelogintype(type){
				this.loginform.typeindex = type
			},
			//发验证码
			setcaptcha(){
				var _this = this
				if(!this.loginform.phone){
					this.$message.error("请输入手机号")
					return false;
				}
				var params = {}
				params["phone"] = this.loginform.phone
				this.getCodeisWaiting = true //禁止点击
				showLoading()
				this.$http.post('app/hlexam/login/captchaPhone', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						_this.$message.success("验证码发送成功")
						//倒计时
						_this.setTimer()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//倒计时
			setTimer() {
			    var holdTime = 60;
			    this.getCodeisWaiting = true;
			    var Timer = setInterval(() => {
			    if (holdTime <= 0) {
			        this.getCodeisWaiting = false;
			        this.captchatext = "重新获取";
			        clearInterval(Timer);
			        return;
			    }
			    this.captchatext = holdTime + "s可重发";
			    holdTime--;
			    }, 1000);
			},
			//关闭登录弹窗的回调
			closeLoginform() {
				this.$emit("handlezhuceiForm", 'close')
			},
		}
	};
</script>
<style lang="scss" scoped>
.loginForm_box{
	width: 100%;
	min-height: calc(100vh - 123px);
	display: flex;
	align-items: center;
	justify-content: center;
	.box1{
		width: 438px;
		border-radius: 12px;
		background: #FFF;
		box-shadow: 0px 9px 9.3px 0px rgba(55, 83, 236, 0.10);
		padding: 28px 24px;
		box-sizing: border-box;
		border: 1px solid #1E86FD;
		position: relative;
		z-index: 10;
		.typeinfo{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.typeobj{
				width: 188px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border-radius: 4px;
				border: 1px solid #1E86FD;
				box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.13);
				color: #1E86FD;
				cursor: pointer;
				&.active{
					background: #1E86FD;
					color: #ffffff;
				}
			}
		}
		.inputbox{
			margin-top: 10px;
		}
		.captchabtn{
			margin-left: 10px;
			width: 112px;
			// height: 38px;
			// line-height: 38px;
			background: #1E86FD;
			color: #ffffff;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #1E86FD;
			box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.13);
			cursor: pointer;
		}
		.checkbox{
			.ml{
				margin-left: 5px;
			}
		}
		.loginbtn{
			margin-top: 10px;
			width: 100%;
			height: 48px;
			line-height: 48px;
			background: #1E86FD;
			color: #ffffff;
			text-align: center;
			border-radius: 8px;
			border: 1px solid #1E86FD;
			box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.13);
			cursor: pointer;
		}
		.forgetbox{
			margin-top: 15px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.text{
				color: #1E86FD;
				cursor: pointer;
			}
		}
	}
}
</style>